@keyframes slideIn {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slideOut {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}

.modalSlideIn {
    animation: slideIn 0.3s forwards;
}

.modalSlideOut {
    animation: slideOut 0.3s forwards;
}
